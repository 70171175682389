import styled from "styled-components";

const Styles = {
    StyledFooter: styled.footer`
        background-color: #ECF1FE;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 50px;
    `,
    FooterContent: styled.div`
        margin: 15px;
        width: ${(props) => props.theme.breakPoints.desktop};
        @media only screen and (max-width:${(props) => props.theme.breakPoints.desktop}) {
            width: 100%;
        }
        & a {
            transition: 0.2s;
            &:hover{
                color: ${(props) => props.theme.color.mainBrand};
                cursor: pointer;
            }
        }
    `,
    FooterTekst: styled.span`
        font-size: ${(props) => props.theme.fontSize.normal};
    `,
    StyledAddress: styled.address`
        display: flex;
        flex-direction: column;
        font-style:normal;
        & a {
            color: ${(props) => props.theme.color.black};
            text-decoration: none;
        }
    `,
    Col: styled.div`
         display: flex;
        flex-direction: column;
    `,
    SocialInfoWrapper: styled.div`
    position: relative;
    bottom: -10px;
    right: 15px;
    z-index: 100;
    & > address{
        display: flex;
        flex-direction: row;
        font-style: normal;
        color: ${(props) => props.theme.color.secondBrand};
        & > div{
            margin-left: 50px;

        }
        @media only screen and (max-width: ${(props) => props.theme.breakPoints.tablet}) {
            flex-direction: column;
            & > div{
                margin-left: 15px;
                margin-bottom: 15px;
            }
        }
    }
    @media only screen and (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        right: unset;   
        bottom: -50px;
    }
    & a {
        color: ${(props) => props.theme.color.secondBrand};
        transition: 0.3s;
        & :hover{
            color: ${(props) => props.theme.color.mainBrand};
        }
    }
`,
}
export default Styles