import React from 'react'
import Styles from './styles'
import H2 from '../../typography/H2'
import H3 from '../../typography/H3'
import DoubleGrid from '../../components/DoubleGrid'
import { myTheme } from '../../MyTheme'
import { Link } from 'react-scroll'
import SocialIcon from '../../components/SocialIcon'
import { VectorImages } from '../../images'

const {fontSize:{big,huge}} = myTheme

const { FacebookIconImg, InstagramIconImg, LinkedInIconImg } = VectorImages;

const { StyledFooter, FooterContent, SocialInfoWrapper } = Styles

const Footer = () => {
    return (
        <StyledFooter>
            <FooterContent>
            <SocialInfoWrapper>
          <address>
            <div>
              <span>Żmigrodzka 244</span>
              <br />
              <span>51-131 Wrocław, Poland</span>
            </div>
            <div>
              <span>+48 797 743 064</span>
              <br />
              <span>
                <a href="mailto:office@oakfusion.pl">office@oakfusion.pl</a>
              </span>
            </div>
            <div>
              <SocialIcon icon={FacebookIconImg} to="https://www.facebook.com/OakfusionSoftwareHouse/" />
              <SocialIcon icon={InstagramIconImg} to="https://www.instagram.com/oakfusion/" />
              <SocialIcon icon={LinkedInIconImg} to="https://pl.linkedin.com/company/oakfusion" />
            </div>
          </address>
        </SocialInfoWrapper>
            </FooterContent>
        </StyledFooter>
    )
}

export default Footer
