import styled from "styled-components";

const Styles = {
    SocialIconImg: styled.img`
        width: 32px;
        height: 32px;
        margin: 5px;
    `
}

export default Styles