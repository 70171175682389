import ArrowButtonImg from "./vector/buttonArrow.svg";
import FacebookIconImg from "./vector/facebookIcon.svg";
import InstagramIconImg from "./vector/instagramIcon.svg";
import LinkedInIconImg from "./vector/linkedinIcon.svg";
import FlagIconImg from "./vector/flag.svg";
import FaviconImg from "./raster/oakfusion.png";

const RasterImages = {
    FaviconImg
};

const VectorImages = {
    ArrowButtonImg,
    FacebookIconImg,
    InstagramIconImg,
    LinkedInIconImg,
    FlagIconImg
}

export { RasterImages, VectorImages }
