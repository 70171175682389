import React, {PropsWithChildren} from "react";
import Styles from "./styles";

const { SocialIconImg } = Styles

interface ISocialIcon {
    icon: string,
    to: string
}

const SocialIcon = ({children, icon, to}:PropsWithChildren<ISocialIcon>) => {
    return <a href={to} target="_blank">
        <SocialIconImg src={icon}/>
    </a>
}

export default SocialIcon